import React, { Component } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import MobileHeader from '../../components/MoblieHeader/mobileHeader'
import MobileFooter from "../../components/MobileFooter/MobileFooter";
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay, Pagination } from 'swiper'
import "swiper/css";
import isMobile from "../../utils/isInWebView";
import "./home.css";
const mobile = isMobile();



// 首页
export class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: {
                current: 1,
                currents: 1,
                current1: 1,
                current2: 1,
                current3: 1,
                current4: 1,
                current5: 1,
                current6: 1,
            }
        }
    }
    tabSwitch(index = 1) {
        this.setState({
            tab: {
                ...this.state.tab,
                current: index
            }
        })
    }
    tabSwitchs(index = 1) {
        this.setState({
            tab: {
                ...this.state.tab,
                currents: index
            }
        })
    }
    tabSwitch1(index = 1) {
        this.setState({
            tab: {
                ...this.state.tab,
                current1: index
            }
        })
    }
    tabSwitch2(index = 1) {
        this.setState({
            tab: {
                ...this.state.tab,
                current2: index
            }
        })
    }
    tabSwitch3(index = 1) {
        this.setState({
            tab: {
                ...this.state.tab,
                current3: index
            }
        })
    }
    tabSwitch4(index = 1) {
        this.setState({
            tab: {
                ...this.state.tab,
                current4: index
            }
        })
    }
    tabSwitch5(index = 1) {
        this.setState({
            tab: {
                ...this.state.tab,
                current5: index
            }
        })
    }
    tabSwitch6(index = 1) {
        this.setState({
            tab: {
                ...this.state.tab,
                current6: index
            }
        })
    }
    componentDidMount() {
        this.tabSwitch()
        this.tabSwitchs()
        this.tabSwitch1()
        this.tabSwitch2()
        this.tabSwitch3()
        this.tabSwitch4()
        this.tabSwitch5()
        this.tabSwitch6()
    }
    renderDesktop = () => {
        const { tab } = this.state
        return (
            <div className="pc-continer">
                <Header />
                <div className="swiperBox">
                    <Swiper
                        modules={[Navigation, Autoplay, Pagination]}
                        loop={true}
                        autoplay={{ delay: 30000 }}
                        // spaceBetween={100}
                        // slidesPerView={3}
                        onSlideChange={() => console.log("slide change")}
                        onSwiper={(swiper) => console.log(swiper)}
                        pagination={{ clickable: true }}
                    >
                        <SwiperSlide><div className="side">
                            {/* <div className="side-con"></div> */}
                            <a href="https://www14.53kf.com/webCompany.php?arg=10708171&kf_sign=TQyOTMTY1Oc4MDEyMDE5NjI5MzMyMDA2NzI3MDgxNzE%253D&style=1" target='blank'>
                                <img src={require('../../assets/image/home/banner1.png')} alt="" />
                            </a>
                        </div></SwiperSlide>
                        {/* <SwiperSlide><div className="side">
                            <a href="https://www14.53kf.com/webCompany.php?arg=10708171&kf_sign=TQyOTMTY1Oc4MDEyMDE5NjI5MzMyMDA2NzI3MDgxNzE%253D&style=1" target='blank'>
                                <img src={require('../../assets/image/home/banner2.png')} alt="" />
                            </a>
                            </div></SwiperSlide> */}
                        <SwiperSlide><div className="side"><a href="https://www14.53kf.com/webCompany.php?arg=10708171&kf_sign=TQyOTMTY1Oc4MDEyMDE5NjI5MzMyMDA2NzI3MDgxNzE%253D&style=1" target='blank'>
                                <img src={require('../../assets/image/home/banner1.png')} alt="" />
                            </a></div></SwiperSlide>
                        {/* <SwiperSlide><div className="side"><a href="https://www14.53kf.com/webCompany.php?arg=10708171&kf_sign=TQyOTMTY1Oc4MDEyMDE5NjI5MzMyMDA2NzI3MDgxNzE%253D&style=1" target='blank'>
                                <img src={require('../../assets/image/home/banner2.png')} alt="" />
                            </a></div></SwiperSlide> */}
                    </Swiper>
                </div>
                <div className="interface-new">
                    <div className="interface-news">
                        {/* <div className="hov-box">
                            <li onMouseOver={() => { this.tabSwitchs(1) }} className={tab.currents === 1 ? 'cur' : ''}>
                                <h6>创思万象如何用产品...</h6>
                                <p>腾讯产业互联网：5年时间，160万…</p>
                                <span>点击查看<img src={require('../../assets/image/home/jt.png')} alt="" /></span>
                            </li>
                            <li onMouseOver={() => { this.tabSwitchs(2) }} className={tab.currents === 2 ? 'cur' : ''}>
                                <h6>创思万象如何用产品...</h6>
                                <p>腾讯产业互联网：5年时间，160万…</p>
                                <span>点击查看<img src={require('../../assets/image/home/jt.png')} alt="" /></span>
                            </li>
                            <li onMouseOver={() => { this.tabSwitchs(3) }} className={tab.currents === 3 ? 'cur' : ''}>
                                <h6>创思万象如何用产品...</h6>
                                <p>腾讯产业互联网：5年时间，160万…</p>
                                <span>点击查看<img src={require('../../assets/image/home/jt.png')} alt="" /></span>
                            </li>
                            <li onMouseOver={() => { this.tabSwitchs(4) }} className={tab.currents === 4 ? 'cur' : ''}>
                                <h6>创思万象如何用产品...</h6>
                                <p>腾讯产业互联网：5年时间，160万…</p>
                                <span>点击查看<img src={require('../../assets/image/home/jt.png')} alt="" /></span>
                            </li>
                        </div> */}
                        {/* <div className="radiation">
                            <li>
                                <img src={require('../../assets/image/home/icon1.png')} alt="" />
                                <p>100<span>+省市自治区</span></p>
                                <h6>业务辐射范围</h6>
                            </li>
                            <li>
                                <img src={require('../../assets/image/home/icon2.png')} alt="" />
                                <p>5000<span>+</span></p>
                                <h6>合作机构数量</h6>
                            </li>
                            <li>
                                <img src={require('../../assets/image/home/icon3.png')} alt="" />
                                <p>60%<span>+</span></p>
                                <h6>合作机构复购率</h6>
                            </li>
                            <li>
                                <img src={require('../../assets/image/home/icon4.png')} alt="" />
                                <p>10000<span>+场</span></p>
                                <h6>私域直播活动</h6>
                            </li>

                        </div> */}
                        <div className="business" style={{paddingTop:108}}>
                            <h2>创思万象 核心业务</h2>
                            <p>私域直播全案策划落地执行，实现业绩高增长</p>
                            <div className="hoverBox">
                                <div className="hoverLeft">
                                    <li onMouseOver={() => { this.tabSwitch(1) }} className={tab.current === 1 ? 'cur' : ''}>
                                        <h4>医美私域<br />直播</h4>
                                        <img className="img1" src={require('../../assets/image/home/img1（2）.png')} alt="" />
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch(2) }} className={tab.current === 2 ? 'cur' : ''}>
                                        <h4>口腔私域<br />直播</h4>
                                        <img className="img2" src={require('../../assets/image/home/img2.png')} alt="" />
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch(3) }} className={tab.current === 3 ? 'cur' : ''}>
                                        <h4>植发机构<br />私域直播</h4>
                                        <img className="img3" src={require('../../assets/image/home/img3.png')} alt="" />
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch(4) }} className={tab.current === 4 ? 'cur' : ''}>
                                        <h4>美业私域<br />直播软件</h4>
                                        <img className="img4" src={require('../../assets/image/home/img4.png')} alt="" />
                                    </li>
                                </div>
                                <div className="hoverRight">
                                    <div className={tab.current === 1 ? 'tab_con_li current' : 'tab_con_li'}>
                                        <h3>盘活私域流量，医美直播逆风翻盘</h3>
                                        <p>线上线下多渠道引流用户到私域流量池，通过分析满足用户需求、运营用户关系，将<br />内容反复触达用户、低成本进行用户裂变，实现医美项目高转化交付。</p>
                                        <div className="pp">
                                            <img className="before" src={require('../../assets/image/home/double-lead.png')} alt="" /> <span>转型轻医美，直播逆风翻盘！</span>
                                            <img className="back" src={require('../../assets/image/home/Back-double.png')} alt="" />
                                        </div>
                                        <img className="img6" src={require('../../assets/image/home/img6.png')} alt="" />
                                        <div className="fontBox">
                                            <p>通过本次私域直播推出定制轻医美品项和升单体系，盘活公域用户上门升<br />单复购，帮助机构重新积累其私域流量池，在每月营业额呈现下降的情况下，通过私域直播实现300%业绩增长。</p>
                                        </div>
                                        <div className="disBox">
                                            <img src={require('../../assets/image/home/img5.png')} alt="" />
                                            <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">方案咨询</a>
                                        </div>
                                    </div>
                                    <div className={tab.current === 2 ? 'tab_con_li current' : 'tab_con_li'}>
                                        <h3>千人私域存量 口腔机构直播引爆门店</h3>
                                        <p>口腔行业具有明显的低频、强关系属性。针对客户设置低价引流卡项，把客户留存私<br />域进行加强教育，通过社群运营和私域直播直接在线上完成转化。</p>
                                        <div className="pps">
                                            <img className="before" src={require('../../assets/image/home/double-lead.png')} alt="" /> <span>口腔私域一站式服务，门店业绩大暴涨</span>
                                            <img className="back" src={require('../../assets/image/home/Back-double.png')} alt="" />
                                        </div>
                                        <div className="fontBox">
                                            <p>口腔门诊越发密集，一条街上甚至都有七八家，线上的广告投放和获取用户线索也越来越难，门诊获客成本越来越高、效果也不稳定，创思万象的口腔私域运营一站式服务让社群运营和转化变得简单，门店业绩暴涨。</p>
                                        </div>
                                        <div className="disBox">
                                            <img src={require('../../assets/image/home/img5（2）.png')} alt="" />
                                            <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">方案咨询</a>
                                        </div>
                                        <img className="img6" src={require('../../assets/image/home/img7.png')} alt="" />
                                    </div>
                                    <div className={tab.current === 3 ? 'tab_con_li current' : 'tab_con_li'}>
                                        <h3>直播助力植发品牌内容破局</h3>
                                        <p>获客难成为当下植发机构的共同问题，直播快速触达消费群体，实现高效转化，降低<br />获客成本，为植发机构打造双向流量闭环，助力销售业绩高速增长。</p>
                                        <div className="ppss">
                                            <img className="before" src={require('../../assets/image/home/double-lead.png')} alt="" /> <span>一场直播完成一个月业绩</span>
                                            <img className="back" src={require('../../assets/image/home/Back-double.png')} alt="" />
                                        </div>
                                        <div className="fontBox">
                                            <p>头皮检测直播呈现引爆清洁养发单品销量，美学专家现场展示传递美学理<br />念教育用户，直播品相侧重养护，让内容成为本场直播的核心，主播演示<br />亲测功效嗨翻直播丰富互动氛围，一场直播完成了一个月的业绩。</p>
                                        </div>
                                        <div className="disBox">
                                            <img src={require('../../assets/image/home/img5（3）.png')} alt="" />
                                            <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">方案咨询</a>
                                        </div>
                                        <img className="img6" src={require('../../assets/image/home/img8.png')} alt="" />
                                    </div>
                                    <div className={tab.current === 4 ? 'tab_con_li current' : 'tab_con_li'}>
                                        <h3>自研直播系统 多项核心技术专利</h3>
                                        <p>创思万象自有知识产权直播系统，技术全程自主，被评为高新技术企业，拥有多项软<br />件技术专利，针对美业私域直播需求首创多项功能，100+直播互动功能，更懂医美私<br />域直播。</p>
                                        <div className="pp">
                                            <img className="before" src={require('../../assets/image/home/double-lead.png')} alt="" /> <span>私域直播：2023美业新标配</span>
                                            <img className="back" src={require('../../assets/image/home/Back-double.png')} alt="" />
                                        </div>
                                        <div className="fontsBox">
                                            <p>当下正值直播风口，私域直播已经成为了美业的标配，通过直播可以让客<br />户更直观的看待各项目更多的信息，通过主播的全程互动讲解，真人上镜<br />展示，强烈地对比展示效果，让用户的感官体验更直观，加上直播间的各<br />种互动玩法，大大增加了转化。</p>
                                        </div>
                                        <div className="disBox">
                                            <img src={require('../../assets/image/home/img5.png')} alt="" />
                                            <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">方案咨询</a>
                                        </div>
                                        <img className="img6" src={require('../../assets/image/home/img9.png')} alt="" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="consumption">
                    <div className="consumptions">
                        <h2>消费医疗机构都面临的业绩增长困局</h2>
                        <p>行业竞争加剧，流量成本和业绩转化的双重考验</p>
                        <div className="consumption-hovBox">
                            <li onMouseOver={() => { this.tabSwitch1(1) }} className={tab.current1 === 1 ? 'cur' : ''}>
                                <h6>医美获客成本高涨</h6>
                                <p>流量红利期已过，公域获客成本高，搭建医美私域流<br />量没有方法。</p>
                                <img className="one" src={require('../../assets/image/home/one.png')}></img>

                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">
                                    <span>查看解决方案</span>
                                <img className="whjt" src={require('../../assets/image/home/jiantou.png')} alt="" />
                                </a>
                            </li>
                            <li onMouseOver={() => { this.tabSwitch1(2) }} className={tab.current1 === 2 ? 'cur' : ''}>
                                <h6>私域流量运营效率低</h6>
                                <p>私域流量的意向用户比较分散，没有集中到同个平台<br />管理，导致运营困难。</p>
                                <img className="two" src={require('../../assets/image/home/two.png')}></img>
                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">
                                    <span>查看解决方案</span><img className="whjt" src={require('../../assets/image/home/jiantou.png')} alt="" />
                                </a>
                            </li>
                            <li onMouseOver={() => { this.tabSwitch1(3) }} className={tab.current1 === 3 ? 'cur' : ''}>
                                <h6>私域转化成交效果差</h6>
                                <p>用户没有分层，画像不清晰，内容营销没有针对性，<br />活动没有效果。</p>
                                <img className="three" src={require('../../assets/image/home/three.png')}></img>
                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">
                                <span>查看解决方案</span><img className="whjt" src={require('../../assets/image/home/jiantou.png')} alt="" />
                                </a>
                            </li>
                            <li onMouseOver={() => { this.tabSwitch1(4) }} className={tab.current1 === 4 ? 'cur' : ''}>
                                <h6>营销工具不好用</h6>
                                <p>各种营销工具标榜的功能如何强大，但真正落地的比<br />较少，用起来复杂费时费力。</p>
                                <img className="fore" src={require('../../assets/image/home/fore.png')}></img>
                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">
                                <span>查看解决方案</span><img className="whjt" src={require('../../assets/image/home/jiantou.png')} alt="" />
                                </a>
                            </li>
                            <li onMouseOver={() => { this.tabSwitch1(5) }} className={tab.current1 === 5 ? 'cur' : ''}>
                                <h6>客户成单和复购率低</h6>
                                <p>客户成交下单后缺乏有效的运维体系，社群运营活跃<br />度低，二次复购率低。</p>
                                <img className="five" src={require('../../assets/image/home/five.png')}></img>
                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">
                                <span>查看解决方案</span><img className="whjt" src={require('../../assets/image/home/jiantou.png')} alt="" />
                                </a>
                            </li>
                            <li onMouseOver={() => { this.tabSwitch1(6) }} className={tab.current1 === 6 ? 'cur' : ''}>
                                <h6>渠道合作交付标准低</h6>
                                <p>外部的三方渠道合作方执行落地效果差，细节把控不<br />到位，达不到约定交付标准。</p>
                                <img className="six" src={require('../../assets/image/home/six.png')}></img>
                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">
                                <span>查看解决方案</span><img className="whjt" src={require('../../assets/image/home/jiantou.png')} alt="" />
                                </a>
                            </li>
                        </div>
                    </div>
                </div>
                <div className="paper">
                    <div className="papers">
                        <h2>医美私域营销白皮书</h2>
                        <p>行业案例+实战方法，助力数字化转型，高效获客，提升营销效率</p>
                        <div className="Cash">
                            <li onMouseOver={() => { this.tabSwitch2(1) }} className={tab.current2 === 1 ? 'cur' : ''}>
                                <img src={require('../../assets/image/home/1L-img.png')} alt="" />
                                <h4>医美私域流量大变现</h4>
                                <p>2023｜医美机构私域流量及社<br />群运营作战全景地图<br />构建私域运营体系，分享、促<br />活、拉新、 裂变及社群运营<br />SOP手册</p>
                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">立即领取</a>
                            </li>
                            <li onMouseOver={() => { this.tabSwitch2(2) }} className={tab.current2 === 2 ? 'cur' : ''}>
                                <img src={require('../../assets/image/home/1L-img2.png')} alt="" />
                                <h4>解锁公域流量新玩法</h4>
                                <p>2023｜公域平台流量全面运营<br />获客作战地图<br />公域平台流量分配机制，矩阵号<br />低成本广告投放引流</p>
                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">立即领取</a>
                            </li>
                            <li onMouseOver={() => { this.tabSwitch2(3) }} className={tab.current2 === 3 ? 'cur' : ''}>
                                <img src={require('../../assets/image/home/1L-img3.png')} alt="" />
                                <h4>引爆本地生活业绩新增长</h4>
                                <p>2023｜医美机构本地生活引流<br />获客运营执行方案<br />抖音及美团本地生活运营，锁定<br />区域流量，实现业绩增长</p>
                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">立即领取</a>
                            </li>
                            <li onMouseOver={() => { this.tabSwitch2(4) }} className={tab.current2 === 4 ? 'cur' : ''}>
                                <img src={require('../../assets/image/home/1L-img4.png')} alt="" />
                                <h4>医美风向标发展趋势报告</h4>
                                <p>2022｜医美行业年度发展报告<br />白皮书<br />
                                    多数据多维度呈现，医美机构研<br />究及行业增长机会研究报告</p>
                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">立即领取</a>
                            </li>
                        </div>
                    </div>
                </div>
                <div className="advantage">
                    <div className="advantages">
                        <h2>创思万象品牌优势</h2>
                        <p>专注于消费医疗赛道，头部机构直选品牌</p>
                        <div className="advantageBox">
                            <li onMouseOver={() => { this.tabSwitch3(1) }} className={tab.current3 === 1 ? 'cur' : ''}>
                                <img src={require('../../assets/image/home/01_off.png')} alt="" />
                                <h3>01 医美大数据</h3>
                                <p>累积为医美行业提供了2万小时+的<br />直播服务，大数据算法能准确知晓医<br />美风向标，将大数据赋能，提升运营<br />效率，实现高指标交付。</p>
                            </li>
                            <li onMouseOver={() => { this.tabSwitch3(2) }} className={tab.current3 === 2 ? 'cur' : ''}>
                                <img src={require('../../assets/image/home/02_off.png')} alt="" />
                                <h3>02 全案执行优势</h3>
                                <p>创思万象提供全案落地服务，从系<br />统、策划，到一站式落地执行交付，<br />迄今为止已为5000+医美/口腔/植<br />发/眼科等机构提供私域直播服务。</p>
                            </li>
                            <li onMouseOver={() => { this.tabSwitch3(3) }} className={tab.current3 === 3 ? 'cur' : ''}>
                                <img src={require('../../assets/image/home/03_off.png')} alt="" />
                                <h3>03 自研直播系统</h3>
                                <p>自有知识产权直播系统，多项软件技<br />术专利，从在线面诊，到直播数据<br />CDM大屏系统，100+直播互动功<br />能，更懂医美私域直播。</p>
                            </li>
                            <li onMouseOver={() => { this.tabSwitch3(4) }} className={tab.current3 === 4 ? 'cur' : ''}>
                                <img src={require('../../assets/image/home/04_off.png')} alt="" />
                                <h3>04 品牌优势</h3>
                                <p>创思万象为医美而生，作为医美头部<br />机构直选的服务品牌，创思万象只专<br />注医美行业全案策划落地执行这一件<br />事，帮助医美机构成功，懂运营，更<br />懂医美！</p>
                            </li>
                        </div>
                    </div>
                </div>
                <div className="support">
                    <div className="supports">
                        <h2>核心服务支持</h2>
                        <p>创思万象二十四项体系，全方位保障一场大卖直播</p>
                        <div className="supportBox">
                            <div className="supportTop">
                                <ul>
                                    <li onMouseOver={() => { this.tabSwitch4(1) }} className={tab.current4 === 1 ? 'cur' : ''}>
                                        八大团队体系
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch4(2) }} className={tab.current4 === 2 ? 'cur' : ''}>
                                        八大美学标准
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch4(3) }} className={tab.current4 === 3 ? 'cur' : ''}>
                                        八大自研系统
                                    </li>
                                </ul>
                            </div>
                            <div className="supportBom">
                                <div className={tab.current4 === 1 ? 'tab_con_li current' : 'tab_con_li'}>
                                    <li onMouseOver={() => { this.tabSwitch5(1) }} className={tab.current5 === 1 ? 'cur' : ''}>
                                        <h4>操盘手团队</h4>
                                        <img src={require('../../assets/image/home/2L-img1.png')} alt="" />
                                        <p>医美私域直播操盘手团队，百万级GMV<br/>场次的操盘经验，全案策划SOP全流程<br/>保障项目顺利交付。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(2) }} className={tab.current5 === 2 ? 'cur' : ''}>
                                        <h4>医美主播团队</h4>
                                        <img src={require('../../assets/image/home/2L-img2.png')} alt="" />
                                        <p>医美百万级GMV优质签约合作主播，根<br/>据机构量身定制推荐匹配最合适主播，<br/>让直播大卖。</p>
                                    </li>
                                    
                                    <li onMouseOver={() => { this.tabSwitch5(3) }} className={tab.current5 === 3 ? 'cur' : ''}>
                                        <h4>美学设计团队</h4>
                                        <img src={require('../../assets/image/home/2L-img3.png')} alt="" />
                                        <p>专业设计团队全流程服务，朋友圈、社<br/>群、直播间、商品全场景海报素材一体<br/>化设计，让私域直播效果更专业。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(4) }} className={tab.current5 === 4 ? 'cur' : ''}>
                                        <h4>社群运营团队</h4>
                                        <img src={require('../../assets/image/home/2L-img4.png')} alt="" />
                                        <p>医美私域社群运营团队，从社群引流、<br/>种子用户裂变、促活拉新、种草科普到<br/>线上直播间互动营销，提升直播间场观<br/>人次。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(5) }} className={tab.current5 === 5 ? 'cur' : ''}>
                                        <h4>现场执行团队</h4>
                                        <img src={require('../../assets/image/home/2L-img5.png')} alt="" />
                                        <p>专业直播现场执行团队，直播间现场布<br/>光，绿幕、机位设计，场控导播台执<br/>行，配合主播上架产品，严苛现场执行<br/>标准，完成执行交付。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(6) }} className={tab.current5 === 6 ? 'cur' : ''}>
                                        <h4>技术保障团队</h4>
                                        <img src={require('../../assets/image/home/2L-img8.png')} alt="" />
                                        <p>自研八大数字化直播系统，打通全流程<br/>数据环节，及时洞察客户意向促进成<br/>交；完善的社群和直播间安全防火墙，<br/>保障客户资产安全，免受竞对骚扰。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(7) }} className={tab.current5 === 7 ? 'cur' : ''}>
                                        <h4>大数据团队</h4>
                                        <img src={require('../../assets/image/home/2L-img6.png')} alt="" />
                                        <p>基于上万医美直播场次的大数据，对时<br/>下流行的热门项目进行排品，根据大数<br/>据进行反馈指导优化直播间的话术脚本<br/>方案。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(8) }} className={tab.current5 === 8 ? 'cur' : ''}>
                                        <h4>服务支持团队</h4>
                                        <img src={require('../../assets/image/home/2L-img7.png')} alt="" />
                                        <p>创思万象线上服务中心及24小时电话支<br/>持，随时解答直播间以及全案策划落地<br/>执行的各种问题，全方位保障直播间的<br/>稳定运行。</p>
                                    </li>
                                </div>
                                <div className={tab.current4 === 2 ? 'tab_con_li current' : 'tab_con_li'}>
                                    <li onMouseOver={() => { this.tabSwitch5(1) }} className={tab.current5 === 1 ? 'cur' : ''}>
                                        <h4>专属视觉设海报</h4>
                                        <img src={require('../../assets/image/home/3L-img1.png')} alt="" />
                                        <p>定制化社群推广海报、倒计时预告海<br/>报、品项包装海报、裂变分享海报等素<br/>材全覆盖。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(2) }} className={tab.current5 === 2 ? 'cur' : ''}>
                                        <h4>产品开发搭配</h4>
                                        <img src={require('../../assets/image/home/3L-img2.png')} alt="" />
                                        <p>直播系统和内容的融合，品项介绍、优<br/>惠折扣、案例效果对比，到转盘抽奖、<br/>随机福袋和红包雨等一目了然。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(3) }} className={tab.current5 === 3 ? 'cur' : ''}>
                                        <h4>活动方案策划</h4>
                                        <img src={require('../../assets/image/home/3L-img3.png')} alt="" />
                                        <p>品牌年中庆周年庆、女神节、双11双12<br/>嗨购、年货节等，紧跟热点主题进行策<br/>划，让私域直播大卖。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(4) }} className={tab.current5 === 4 ? 'cur' : ''}>
                                        <h4>品牌直播间</h4>
                                        <img src={require('../../assets/image/home/3L-img4.png')} alt="" />
                                        <p>直播间画面美学布局，注重用户体验，<br/>人性化设计理念，打造美学直播间。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(5) }} className={tab.current5 === 5 ? 'cur' : ''}>
                                        <h4>金牌主播阵容</h4>
                                        <img src={require('../../assets/image/home/3L-img5.png')} alt="" />
                                        <p>医美行业最强主播阵容，100+签约合作<br/>主播，单场最高GMV破千万，打造医美<br/>行业标杆。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(6) }} className={tab.current5 === 6 ? 'cur' : ''}>
                                        <h4>高品质画面质感</h4>
                                        <img src={require('../../assets/image/home/3L-img6.png')} alt="" />
                                        <p>医美直播专属视频风格，万场直播间视<br/>频算法优化，打造一场高清直播，让画<br/>面更具真实美感。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(7) }} className={tab.current5 === 7 ? 'cur' : ''}>
                                        <h4>专属互动体验功能</h4>
                                        <img src={require('../../assets/image/home/3L-img7.png')} alt="" />
                                        <p>为医美行业量身定制开发的在线面诊、<br/>在线1V1咨询和在线预约等功能，让直播<br/>间直接成交转化变得更简单。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(8) }} className={tab.current5 === 8 ? 'cur' : ''}>
                                        <h4>广电级视频标准</h4>
                                        <img src={require('../../assets/image/home/3L-img8.png')} alt="" />
                                        <p>广电级别的影棚灯光舞美系统、旗舰级<br/>影像设备，直播间专业级硬件设备支持，支持8K视频画面输出，高标准注重细节<br/>执行。</p>
                                    </li>
                                </div>
                                <div className={tab.current4 === 3 ? 'tab_con_li current' : 'tab_con_li'}>
                                <li onMouseOver={() => { this.tabSwitch5(1) }} className={tab.current5 === 1 ? 'cur' : ''}>
                                        <h4>内容生态</h4>
                                        <img src={require('../../assets/image/home/4L-img1.png')} alt="" />
                                        <p>医美运营物料素材库，从分享导图到直<br/>播间布景，让内容生态更懂医美。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(2) }} className={tab.current5 === 2 ? 'cur' : ''}>
                                        <h4>用户管理</h4>
                                        <img src={require('../../assets/image/home/4L-img2.png')} alt="" />
                                        <p>系统识别意向用户，实时推送销售跟<br/>进，自动化促成交。</p>
                                    </li>
                                    
                                    <li onMouseOver={() => { this.tabSwitch5(3) }} className={tab.current5 === 3 ? 'cur' : ''}>
                                        <h4>私域互动</h4>
                                        <img src={require('../../assets/image/home/4L-img3.png')} alt="" />
                                        <p>用户与线上咨询直播间内无缝对接，用<br/>户互动信息滚屏显示。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(4) }} className={tab.current5 === 4 ? 'cur' : ''}>
                                        <h4>裂变拉新</h4>
                                        <img src={require('../../assets/image/home/4L-img4.png')} alt="" />
                                        <p>基于分享推广模式，依托种子用户拓新<br/>客，让社群裂变更简单。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(5) }} className={tab.current5 === 5 ? 'cur' : ''}>
                                        <h4>营销转化</h4>
                                        <img src={require('../../assets/image/home/4L-img5.png')} alt="" />
                                        <p>红包雨、抽奖、秒杀、优惠券、集赞等<br/>营销玩法，营造热烈抢购气氛，促进线<br/>上成交。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(6) }} className={tab.current5 === 6 ? 'cur' : ''}>
                                        <h4>商品运营</h4>
                                        <img src={require('../../assets/image/home/4L-img6.png')} alt="" />
                                        <p>后台实时上架产品，实时推送讲解商<br/>品，打造直播间爆品。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(7) }} className={tab.current5 === 7 ? 'cur' : ''}>
                                        <h4>激励系统</h4>
                                        <img src={require('../../assets/image/home/4L-img7.png')} alt="" />
                                        <p>直播间成交CDM大屏可视化数据呈现，<br/>提高直播间线上转化。</p>
                                    </li>
                                    <li onMouseOver={() => { this.tabSwitch5(8) }} className={tab.current5 === 8 ? 'cur' : ''}>
                                        <h4>大数据系统</h4>
                                        <img src={require('../../assets/image/home/4L-img8.png')} alt="" />
                                        <p>智能大数据分析，100+维度用户属性，<br/>到店核销分账无烦恼。</p>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mechanism">
                    <div className="mechanisms">
                        <h2>部分合作机构</h2>
                        <p>致力于成为美业机构最为信赖的服务品牌</p>
                        <img src={require('../../assets/image/home/bg4.png')} alt="" />
                    </div>
                </div>
                {/* 新闻动态 */}
                {/* <div className="NewsUpdate">
                    <div className="NewsUpdates">
                        <h2>新闻动态</h2>
                        <p>实时动态，立刻知晓，合作共赢</p>
                        <div className="NewsBox">
                            <div className="NewsLeft">
                            <li onMouseOver={() => { this.tabSwitch6(1) }}      className={tab.current6 === 1 ? 'cur' : ''}>
                                <p>03-20<br/><span>2023</span></p>
                                <h5>医美行业利用私域直播，实现业绩一路“狂飙”！</h5>
                                <p className="p">目前医美行业面临几大问题获客成本高，客户沉睡率高，转化效果…</p>
                                
                            </li>
                            <li onMouseOver={() => { this.tabSwitch6(2) }}      className={tab.current6 === 2 ? 'cur' : ''}>
                                <p>03-20<br/><span>2023</span></p>
                                <h5>医美行业利用私域直播，实现业绩一路“狂飙”！</h5>
                                <p className="p">目前医美行业面临几大问题获客成本高，客户沉睡率高，转化效果…</p>
                            </li>
                            <li onMouseOver={() => { this.tabSwitch6(3) }}      className={tab.current6 === 3 ? 'cur' : ''}>
                                <p>03-20<br/><span>2023</span></p>
                                <h5>医美行业利用私域直播，实现业绩一路“狂飙”！</h5>
                                <p className="p">目前医美行业面临几大问题获客成本高，客户沉睡率高，转化效果…</p>
                            </li>
                            <li onMouseOver={() => { this.tabSwitch6(4) }}      className={tab.current6 === 4 ? 'cur' : ''}>
                                <p>03-20<br/><span>2023</span></p>
                                <h5>医美行业利用私域直播，实现业绩一路“狂飙”！</h5>
                                <p className="p">目前医美行业面临几大问题获客成本高，客户沉睡率高，转化效果…</p>
                            </li>
                            </div>
                            <div className="NewsRight">
                            <div className={tab.current4 === 1 ? 'tab_con_li current' : 'tab_con_li'}>
                                <img src={require('../../assets/image/home/new1.png')} alt="" />
                            </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="soldier">
                    <div className="soldiers">
                        <h2>直播一站式服务 助力美业机构业绩增长</h2>
                        <div className="enlargeBox">
                            <li>
                                <div className="scenario-pic"></div>
                                <h4>医美机构全案策划服务</h4>
                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">立即咨询</a>
                            </li>
                            <li>
                                <div className="scenario-pic"></div>
                                <h4>口腔机构全案策划服务</h4>
                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">立即咨询</a>
                            </li>
                            <li>
                                <div className="scenario-pic"></div>
                                <h4>眼科机构全案策划服务</h4>
                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">立即咨询</a>
                            </li>
                            <li><div className="scenario-pic"></div>
                                <h4>其它消费医疗机构</h4>
                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">立即咨询</a>
                            </li>
                            <li><div className="scenario-pic"></div>
                                <h4>诺云私域直播系统</h4>
                                <a href="https://tb.53kf.com/code/client/317c6533bbcd9ab23d95ed18e4d84c454/1" target="_blank">立即咨询</a>
                            </li>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    };
    renderMobile = () => {
        return (
            <div className="mobileHome">
                {/* <MobileHeader />
                <div className="banner"></div>
                <MobileFooter /> */}
            </div>
        )
    };
    render() {
        return mobile ? this.renderMobile() : this.renderDesktop();
    }
}

export default Home;



