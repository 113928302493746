import React, { 
    // useState 
} from 'react'
import "./header.scss"
// import logo from '../../assets/image/header/logo.png'
import $ from 'jquery';
import { Link,NavLink } from 'react-router-dom';


function Header() {
    $(document).ready(function () {
        function scroll() {
            //@ts-ignore
            var tops = $(".nav").offset().tops
            var scrollTop = $(window).scrollTop();
            //@ts-ignore
            if (scrollTop <= 70) {
              $('.nav').css({
                backgroundColor: 'rgba(0, 0, 0, 0)',
                color:'#fff'
              });
              $('.nav').css({
                color:'#fff'
              });
              $('.navCenter a').css({
                color:'#fff'
              });
              $('.navRight a').css({
                color:'#fff'
              })
              $('button').css({
                background: '#FFFFFF',
                color: '#6F24FF',
              });
              $('.navLog').css({
                background:"url('require{'/beauty/src/assets/image/home/mobileLogo.png'}')"
              })
              $('.rightPhone').css({
                background:"url('require{'/beauty/src/assets/image/header/phone.png'}')"
              })
              $('.rightPhone').removeClass('blackPhone');
              $('.navLog').removeClass('navBlackLog')
      
            } else {
              $('.nav').css({
                background: 'hsla(0, 0%, 100%, .9)',
                color:'black'
              });
              $('.rightPhone').addClass('blackPhone');
              $('.navLog').addClass('navBlackLog')
              $('.navCenter a').css({
                color:'black'
              });
              $('.navRight a').css({
                color:'#6F24FF'
              })
              $('button').css({
                background: '#6F24FF',
                color: '#fff',
              });
              $('.navLog').css({
                background:"url('require{'/beauty/src/assets/image/home/mobileLogos.png'}')"
              })
              $('.rightPhone').css({
                background:"url('require{'/beauty/src/assets/image/header/blackPhone.png'}')"
              })
            }
          }
          $(window).on('scroll', function () {
            scroll()
          });
    });
    

    return (
        <div className='nav'>
            <div className='navCon'>
                <div className='navLog'>
                    {/* <img src={require('../../assets/image/header/logo.png')} alt="" /> */}
                </div>
                <div className='navCenter'>
                    {/* <Link to='/'>&emsp;&nbsp;首页</Link> */}
                    {/* <Link to='/Channel/channel'>服务内容</Link>
                    <Link to='/Solution/solution'>解决方案</Link>
                    <Link to=''>案例中心</Link>
                    <Link to='/Private/private'>&nbsp;商学院</Link>
                    <Link to='/Operation/operation'>服务支持</Link>
                    <NavLink to='/About/about'>关于诺云</NavLink> */}
                </div>
                <div className='navRight'>
                    <div className='rightPhone'>
                    {/* <img src={require('../../assets/image/header/phone.png')} alt="" /> */}
                        </div><span>400-869-9377</span>
                    {/* < a className='a' href="">登录</ a>
                    <button>免费体验</button> */}
                </div>
            </div>
            </div>
    )
}
export default Header