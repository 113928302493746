// import React, { Component } from 'react'
// import {BrowserRouter} from "react-router-dom"
// import { Route, Routes} from 'react-router'
// import routerConfig from "./router/routerConfig"
// import RouterView from './router/RouterView'
// import Home from './views/Home/home.jsx'
import Router from './router/index'
// import {Provider} from "react-redux"

// export class App extends Component {

//   render() {
//     return (
//       // <div>1</div>
//       // <Provider store={store}>
//       <BrowserRouter>
//         {/* <RouterView></RouterView> */}
//         <Routes>
//             <Route path='/' element={<Home/>}></Route>
//             {/* <Route path='/Solution/solution' element={<div>ceshi2</div>}></Route> */}
//         </Routes>
//       </BrowserRouter>
//       // </Provider>
//     )
//   }
// }

// export default App;

function App() {
  return (
    <div>
       <Router></Router>
    </div>
    // <div>1</div>
    // <BrowserRouter>
    //   <RouterView></RouterView>
    // </BrowserRouter>
  )
}
export default App;