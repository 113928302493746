import React, { Component } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import MobileHeader from '../../components/MoblieHeader/mobileHeader'
import MobileFooter from "../../components/MobileFooter/MobileFooter";
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay, Pagination } from 'swiper'
import "swiper/css";
import isMobile from "../../utils/isInWebView";
import "./solution.css";
const mobile = isMobile();


// 解决方案

export class solution extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: {
                current: 1,
            }
        }
    }
    tabSwitch(index = 1) {
        this.setState({
            tab: {
                ...this.state.tab,
                current: index
            }
        })
    }
    componentDidMount() {
        this.tabSwitch()
    }
        renderDesktop = () => {
            const {tab}=this.state
            return(
                <div className="pc-solution">
                    <Header />
                    <div className="banner">
                        <div className="banners">
                            <h2>直播间场景搭建 <span>整体解决方案</span></h2>
                            <p>轻松打造T97同款直播间</p>
                            <a href="">了解更多</a>
                        </div>
                    </div>
                    <div className="setUp">
                        <div className="setUps">
                            <h2>常见直播间搭建难题</h2>
                            <img src={require('../../assets/image/solution/img1.png')}></img>
                        </div>
                    </div>
                    <div className="provide">
                        <div className="provides">
                            <h2>诺云为您提供整体解决方案</h2>
                            <ul className="provides-list">
                                <li>
                                    <img className="img" src={require('../../assets/image/solution/img2.png')}></img>
                                    <div className="equipment">
                                        <img src={require('../../assets/image/solution/one.png')}></img>
                                        <p>设备套餐一站配齐<br/>满足多场景需求</p>
                                    </div>
                                </li>
                                <li>
                                <img className="img" src={require('../../assets/image/solution/img3.png')}></img>
                                    <div className="equipment">
                                        <img src={require('../../assets/image/solution/two.png')}></img>
                                        <p>标准化执行流程远<br/>程搭建省心省力</p>
                                    </div>
                                </li>
                                <li>
                                    <img className="img" src={require('../../assets/image/solution/img4.png')}></img>
                                        <div className="equipment">
                                            <img src={require('../../assets/image/solution/three.png')}></img>
                                            <p>尊享优质服务体验<br/>全程无忧</p>
                                        </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="demand">
                        <div className="demands">
                            <h2>设备套餐一键配齐，满足多场景需求</h2>
                            <div className="hoverBox">
                                <div className="hoverTop">
                                    <li onMouseOver={() => { this.tabSwitch(1) }} className={tab.current === 1 ? 'cur' : ''}>实体直播间</li>
                                    <li onMouseOver={() => { this.tabSwitch(2)}} className={tab.current === 2 ? 'cur' : ''}>虚拟直播间</li>
                                    <li onMouseOver={() => { this.tabSwitch(3) }} className={tab.current === 3 ? 'cur' : ''}>多画面直播间</li>
                                </div>
                                <div className="hoverBom">
                                    <div className={tab.current === 1 ? 'tab_con_li current' : 'tab_con_li'}>
                                        <div className="imgFont">
                                            <img className="imgs" src={require('../../assets/image/solution/img5.png')}></img>
                                            <div className="FontBox">
                                                <h3>实体直播间</h3>
                                                <p>满足基础实景直播需求，初次尝试直播，追求性价比的商家</p>
                                                <h6>套餐包括</h6>
                                                <div className="disBox">
                                                    <li>
                                                        <img src={require('../../assets/image/solution/2L-img1.png')}></img>
                                                        <p>直播主机*1</p>
                                                    </li>
                                                    <li>
                                                    <img src={require('../../assets/image/solution/2L-img2.png')}></img>
                                                        <p>全景相机*1</p>
                                                    </li>
                                                    <li>
                                                    <img src={require('../../assets/image/solution/2L-img3.png')}></img>
                                                        <p>音频线*1</p>
                                                    </li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={tab.current === 2 ? 'tab_con_li current' : 'tab_con_li'}>
                                    <div className="imgFont">
                                            <img className="imgs" src={require('../../assets/image/solution/img6.png')}></img>
                                            <div className="FontBox">
                                                <h3>实体直播间</h3>
                                                <p>满足基础实景直播需求，初次尝试直播，追求性价比的商家</p>
                                                <h6>套餐包括</h6>
                                                <div className="disBox">
                                                    <li>
                                                        <img src={require('../../assets/image/solution/2L-img1.png')}></img>
                                                        <p>直播主机*1</p>
                                                    </li>
                                                    <li>
                                                    <img src={require('../../assets/image/solution/2L-img2.png')}></img>
                                                        <p>全景相机*1</p>
                                                    </li>
                                                    <li>
                                                    <img src={require('../../assets/image/solution/2L-img3.png')}></img>
                                                        <p>音频线*1</p>
                                                    </li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={tab.current === 3 ? 'tab_con_li current' : 'tab_con_li'}>
                                    <div className="imgFont">
                                            <img className="imgs" src={require('../../assets/image/solution/img5.png')}></img>
                                            <div className="FontBox">
                                                <h3>实体直播间</h3>
                                                <p>满足基础实景直播需求，初次尝试直播，追求性价比的商家</p>
                                                <h6>套餐包括</h6>
                                                <div className="disBox">
                                                    <li>
                                                        <img src={require('../../assets/image/solution/2L-img1.png')}></img>
                                                        <p>直播主机*1</p>
                                                    </li>
                                                    <li>
                                                    <img src={require('../../assets/image/solution/2L-img2.png')}></img>
                                                        <p>全景相机*1</p>
                                                    </li>
                                                    <li>
                                                    <img src={require('../../assets/image/solution/2L-img3.png')}></img>
                                                        <p>音频线*1</p>
                                                    </li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="process">
                        <div className="processs">
                            <h2>标准化执行流程，远程指导搭建省心省力</h2>
                            <div className="imgBox">
                                <img src={require('../../assets/image/solution/1L-img.png')}></img>
                                <img src={require('../../assets/image/solution/1Limg2.png')}></img>
                                <img src={require('../../assets/image/solution/1L-img3.png')}></img>
                                <img src={require('../../assets/image/solution/1L-img4.png')}></img>
                                <img src={require('../../assets/image/solution/1L-img5.png')}></img>
                                <img src={require('../../assets/image/solution/1L-img6.png')}></img>
                                <img src={require('../../assets/image/solution/1L-img7.png')}></img>
                            </div>
                            <img className="lang" src={require('../../assets/image/solution/lang.png')}></img>
                            <div className="fontBox">
                                <p>设备套餐下单</p>
                                <p>预约远程搭建</p>
                                <p>设备调试</p>
                                <p>灯光调试</p>
                                <p>开启试播</p>
                                <p>产品培训指导</p>
                                <p>完成交付</p>
                            </div>
                        </div>
                    </div>
                    <div className="enjoy">
                        <div className="enjoys">
                            <h2>尊享优质服务体验，全程无忧</h2>
                            <ul className="course">
                                <li>
                                    <img src={require('../../assets/image/solution/icon1.png')}></img>
                                    <div>
                                        <h6>全程陪伴式实操指导</h6>
                                        <p>技术运维全流程全方位技术支持，直播间搭建全程陪伴式<br/>实操指导，工程师全程1对1远程调试</p>
                                    </div>
                                </li>
                                <li>
                                <img src={require('../../assets/image/solution/icon2.png')}></img>
                                    <div>
                                        <h6>专业工程师拥有多年经验</h6>
                                        <p>丰富的合作生态体系，搭建工程师、灯光调试师皆具备<br/>多，年从业经验和专业资质认证</p>
                                    </div>
                                </li>
                                <li>
                                <img src={require('../../assets/image/solution/icon3.png')}></img>
                                    <div>
                                        <h6>提供完整培训资料，指导客户轻松开播</h6>
                                        <p>提供硬件、软件视频说明书和主流APP的直播推流教程，<br/>指导客户实现轻松开播</p>
                                    </div>
                                </li>
                                <li>
                                <img src={require('../../assets/image/solution/icon4.png')}></img>
                                    <div>
                                        <h6>一站式体验，全程无忧</h6>
                                        <p>从下单—到货—安装—验收，客户全程无需操心，轻松上<br/>手</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Footer/>
                </div>
            )
        }
        renderMobile = () => {
                    return (
                        <div className="mobileHome">
                            {/* <MobileHeader />
                            <div className="banner"></div>
                            <MobileFooter /> */}
                        </div>
                    )
                };
        render() {
            return mobile ? this.renderMobile() : this.renderDesktop();
        }
}
export default solution;