import React, { Component } from 'react'
import './private.css'
import Header from '../../components/Header/header'
import Footer from '../../components/Footer/footer'

export class Private extends Component {
  render() {
    return (
      <div className='pc-Private'>
        <Header />
        <div className='banner'>
            <div className='banners'>
                <h2>消费医疗机构<br/>私域直播解决方案</h2>
                <p>高效私域运营触达转化，驱动业绩持续增长</p>
                <a href='https://www14.53kf.com/webCompany.php?arg=10708171&kf_sign=TQyOTMTY1Oc4MDEyMDE5NjI5MzMyMDA2NzI3MDgxNzE%253D&style=1'>了解更多</a>
            </div>
        </div>
        <div className='solve'>
            <div className='solves'>
                <h2>私域营销一体化解决方案</h2>
                <p>基于直播+美业的模式，聚合场景+商城+数据三大能力，助力品牌商家实现私域流量高效变现</p>
                <div className='hoverBox'>
                    <li>
                        <img src={require('../../assets/image/private/1L-icon1.png')}></img>
                        <span>根据直播内容场景<br/>确定营销策略</span>
                    </li>
                   
                    <li>
                        <img src={require('../../assets/image/private/1L-icon2.png')}></img>
                        <span>通过各种渠道<br/>获客引流<br/>进行用户转化</span>
                    </li>
                    <li>
                        <img src={require('../../assets/image/private/1L-icon3.png')}></img>
                        <span>帮助美业机构<br/>聚合专属私域<br/>流量池</span>
                    </li>
                    <li>
                        <img src={require('../../assets/image/private/1L-icon4.png')}></img>
                        <span>直播商品展示<br/>满足带货需求</span>
                    </li>
                    <li>
                        <img src={require('../../assets/image/private/1L-icon5.png')}></img>
                        <span>直播间状态<br/>实时跟踪管控</span>
                    </li>
                    <li>
                        <img src={require('../../assets/image/private/1L-icon6.png')}></img>
                        <span>视频回放<br/>直播数据精准分析<br/>助力新消费</span>
                    </li>
                </div>
                <img className='langs' src={require('../../assets/image/private/lang.png')}></img>
                <div className='col-Box'>
                    <h3>营销</h3>
                    <h3>推广</h3>
                    <h3>引流</h3>
                    <h3>开播</h3>
                    <h3>管理</h3>
                    <h3>复盘</h3>
                </div>
            </div>
        </div>
        <div className='LiveRoom'>
            <div className='LiveRooms'>
                <div className='Leftbox'>
                    <img src={require('../../assets/image/private/img1.png')}></img>
                </div>
                <div className='Rightbox'>
                    <h2>打造专属品牌化直播间</h2>
                    <p>
                        <img src={require('../../assets/image/private/dui.png')}></img>
                        <span>自定义LOGO，自定义分享标识，自定义礼物，自定义海报，预告视频等增加品牌认知</span>
                    </p>
                    <p>
                        <img src={require('../../assets/image/private/dui.png')}></img>
                        <span>支持主流推流方式直播，支持webrtc超低延迟直播，实现主播与观众之间的实时互动</span>
                    </p>
                    <p>
                        <img src={require('../../assets/image/private/dui.png')}></img>
                        <span>多种视频布局方式兼容，打造独一无二的电商带货直播间</span>
                    </p>
                    <a className='detial' href='https://www14.53kf.com/webCompany.php?arg=10708171&kf_sign=TQyOTMTY1Oc4MDEyMDE5NjI5MzMyMDA2NzI3MDgxNzE%253D&style=1'>了解详情</a>
                    <a className='experience' href='https://www14.53kf.com/webCompany.php?arg=10708171&kf_sign=TQyOTMTY1Oc4MDEyMDE5NjI5MzMyMDA2NzI3MDgxNzE%253D&style=1'>免费体验</a>
                </div>
            </div>
        </div>
        <div className='efficiency'>
            <div className='efficiencys'>
                <div className='LeftBoxs'>
                <h2>打造专属品牌化直播间</h2>
                    <p>
                        <img src={require('../../assets/image/private/dui.png')}></img>
                        <span>多种商品展示，商品推荐，商品讲解，客户一键触达商品、促进下单</span>
                    </p>
                    <p>
                        <img src={require('../../assets/image/private/dui.png')}></img>
                        <span>商品SKU支持，基于微信用户限购支持</span>
                    </p>
                    <p>
                        <img src={require('../../assets/image/private/dui.png')}></img>
                        <span>多种商品类型，支持实体发货，虚拟核销，到店尾款，优惠券商品化</span>
                    </p>
                    <p><img src={require('../../assets/image/private/dui.png')}></img><span>拼团、砍价、积分兑换、单品竞拍多样商品营销</span></p>
                    <a className='detial' href='https://www14.53kf.com/webCompany.php?arg=10708171&kf_sign=TQyOTMTY1Oc4MDEyMDE5NjI5MzMyMDA2NzI3MDgxNzE%253D&style=1'>了解详情</a>
                    <a className='experience' href='https://www14.53kf.com/webCompany.php?arg=10708171&kf_sign=TQyOTMTY1Oc4MDEyMDE5NjI5MzMyMDA2NzI3MDgxNzE%253D&style=1'>免费体验</a>
                </div>
                <div className='RightBoxs'>
                    <img src={require('../../assets/image/private/img2.png')}></img>
                </div>
            </div>
        </div>
        <div className='LiveRoom'>
            <div className='LiveRooms'>
                <div className='Leftbox'>
                    <img src={require('../../assets/image/private/img3.png')}></img>
                </div>
                <div className='Rightbox'>
                    <h2>多维度插件赋能营销</h2>
                    <p>
                        <img src={require('../../assets/image/private/dui.png')}></img>
                        <span>红包雨、礼物打赏、优惠券、砍价、拼团、口令抽奖、开播提醒、渠道模式、会员体系满足多种营销方案</span>
                    </p>
                    <p>
                        <img src={require('../../assets/image/private/dui.png')}></img>
                        <span>推广员功能，不断激活老拓新，助力商品裂变传播，协助企业构建完善的线上营销体系</span>
                    </p>
                    <p>
                        <img src={require('../../assets/image/private/dui.png')}></img>
                        <span>观看奖励，任务领券，直播锁客，把握客情提高直播间留存</span>
                    </p>
                    <a className='detial' href='https://www14.53kf.com/webCompany.php?arg=10708171&kf_sign=TQyOTMTY1Oc4MDEyMDE5NjI5MzMyMDA2NzI3MDgxNzE%253D&style=1'>了解详情</a>
                    <a className='experience' href='https://www14.53kf.com/webCompany.php?arg=10708171&kf_sign=TQyOTMTY1Oc4MDEyMDE5NjI5MzMyMDA2NzI3MDgxNzE%253D&style=1'>免费体验</a>
                </div>
            </div>
        </div>
        <div className='efficiency'>
            <div className='efficiencys'>
                <div className='LeftBoxs'>
                <h2>直播全周期数据复盘</h2>
                    <p>
                        <img src={require('../../assets/image/private/dui.png')}></img>
                        <span>用户消费能力分析，用户直播留存分析，新老客分析</span>
                    </p>
                    <p>
                        <img src={require('../../assets/image/private/dui.png')}></img>
                        <span>商品品相分析，用户操作留存分析，商品转化实时监控，助力打造爆款系列营销直播间</span>
                    </p>
                    <p>
                        <img src={require('../../assets/image/private/dui.png')}></img>
                        <span>数据大屏，直播全周期数据一栏，多个维度聚合分析</span>
                    </p>
                    <a className='detial' href='https://www14.53kf.com/webCompany.php?arg=10708171&kf_sign=TQyOTMTY1Oc4MDEyMDE5NjI5MzMyMDA2NzI3MDgxNzE%253D&style=1'>了解详情</a>
                    <a className='experience' href='https://www14.53kf.com/webCompany.php?arg=10708171&kf_sign=TQyOTMTY1Oc4MDEyMDE5NjI5MzMyMDA2NzI3MDgxNzE%253D&style=1'>免费体验</a>
                </div>
                <div className='RightBoxs'>
                    <img src={require('../../assets/image/private/img4.png')}></img>
                </div>
            </div>
        </div>
        <div className='promotion'>
            <div className='promotions'>
                <h2>全渠道引流推广、直播间互动营销</h2>
                <p>打造品牌化直播间，助力企业自播体系构建</p>
                <div className='business'>
                    <li>
                        <img src={require('../../assets/image/private/icon1.png')}></img>
                        <h3>无需业务重构<br/>直播一步嵌入</h3>
                        <p>自有小程序跳转嵌入，自有商城嵌入，<br/>公众号嵌入，门户嵌入，app嵌入</p>
                    </li>
                    <li>
                        <img src={require('../../assets/image/private/icon2.png')}></img>
                        <h3>多维复盘分析<br/>数据可视化</h3>
                        <p>实时数据大屏，数据排行，品项分析，<br/>用户画像分析，用户标签管理</p>
                    </li>
                    <li>
                        <img src={require('../../assets/image/private/icon3.png')}></img>
                        <h3>直播多平台宣发<br/>一键配置推流转播</h3>
                        <p>快手，视频号，抖音，小红书，美团，<br/>新氧，口碑。轻松链接千万流量</p>
                    </li>
                </div>
            </div>
        </div>
        <div className='pole'>
            <div className='poles'>
                <h2>行业标杆客户案例</h2>
                <p>致力于成为美业机构最为信赖的服务品牌</p>
                <img src={require('../../assets/image/private/img5.png')}></img>
            </div>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default Private