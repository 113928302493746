import React, { Component } from 'react'
// 导入所需组件
import Solution from '../views/Solution/solution.jsx'
import Home from '../views/Home/home.jsx'
import About from '../views/About/about.jsx'
import Channel from '../views/Channel/channel.jsx'
import Private from '../views/Private/private.jsx'
import Operation from "../views/Operation/operation.jsx"
// 导入路由依赖
import { Route,BrowserRouter } from 'react-router-dom'
 
export default class index extends Component {
  render() {
    return (
        // 使用BrowserRouter包裹，配置路由
      <BrowserRouter>
         {/* 使用/配置路由默认页；exact严格匹配 */}
        <Route component={Home} path='/' exact></Route>
        <Route component={Solution} path='/Solution/solution'></Route>
        <Route component={About} path='/About/about'></Route>
        <Route component={Channel} path='/Channel/channel'></Route>
        <Route component={Private} path='/Private/private'></Route>
        <Route component={Operation} path='/Operation/operation'></Route>
      </BrowserRouter>
    )
  }
}
