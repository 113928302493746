import React, { Component } from 'react'
import Header from '../../components/Header/header'
import Footer from '../../components/Footer/footer'
// import './operation.css'

export class operation extends Component {
    // constructor(props) {
    //     super(props)
    //     this.state = {
    //         tab: {
    //             current: 1,
    //             currents:1,
    //             current1:1
    //         }
    //     }
    // }
    // tabSwitch(index=1) {
    //     this.setState({
    //       tab: {
    //         ...this.state.tab,
    //         current:index
    //       }
    //     })
    // }
    // tabSwitchs(index=1) {
    //     this.setState({
    //       tab: {
    //         ...this.state.tab,
    //         currents:index
    //       }
    //     })
    // }
    // tabSwitch1(index=1) {
    //     this.setState({
    //       tab: {
    //         ...this.state.tab,
    //         current1:index
    //       }
    //     })
    // }
    // componentDidMount(){
    //     this.tabSwitch()
    //     this.tabSwitchs()
    //     this.tabSwitch1()
    // }
  render() {
    // const {tab} = this.state
    return (
      <div className='Operation-pc'>
        <Header />
        {/* <div className='Operation-banner'>
                    <div className='Operation-banners'>
                        <h2>一站式美业全案运营策划服务</h2>
                        <h3>专为消费医疗行业提供直播全案服务<br/>
                        全面赋能美业机构<span>公域营销+私域运营</span><br/>
                        不投广告也能获得高效回报</h3>
                        <span className='Operation-consultation'>立即咨询</span>
                        <div className='mask-Box'></div>
                        <div className='popup-Box'>
                            <h3>立即咨询</h3>
                            <img src='assets/images/web/aboutus/contact.png'></img>
                            <h4>微信扫码<br/>获取最新全案策划方案</h4>
                        </div>
                    </div>
                </div>
                <div className='Operation-pit'>
                        <div className='Operation-pits'>
                            <h2>一场成功的直播需要避开哪些坑？</h2>
                            <ul className='Operation-ul'>
                            <li onMouseOver={() => {this.tabSwitch1(1)}} className={tab.current1 ===1 ? 'cur':''}>
                                <div className='ul-bgBox'></div>

                                    <div className='Operation-font'>
                                        <h4>品牌机构获客成本太高怎么办？</h4>
                                        <h5>品牌机构需要做好公域引流获客、私域运营转化。</h5>
                                    </div>
                            </li>
                            <li onMouseOver={() => {this.tabSwitch1(2)}} className={tab.current1 ===2 ? 'cur':''}>
                                <div className='ul-bgBox'></div>
                                    <div className='Operation-font'>
                                        <h4>品牌机构沉淀的私域客群如何有效激活？</h4>
                                        <h5>品牌在社群运营和直播邀约过程中该注意什么呢？</h5>
                                    </div>
                            </li>
                            <li onMouseOver={() => {this.tabSwitch1(3)}} className={tab.current1 ===3 ? 'cur':''}>
                                <div className='ul-bgBox'></div>
                                    <div className='Operation-font'>
                                        <h4>品牌机构如何制定有效的带货策略？</h4>
                                        <h5>怎么打磨直播脚本？怎么梳理直播的关键目标呢？</h5>
                                    </div>
                            </li>
                            <li onMouseOver={() => {this.tabSwitch1(4)}} className={tab.current1 ===4 ? 'cur':''}>
                                <div className='ul-bgBox'></div>
                                    <div className='Operation-font'>
                                        <h4>品牌想要提高直播间的质感该怎么做？</h4>
                                        <h5>硬件设备怎么选择？直播间视觉设计是否协调？主播是否合适？</h5>
                                    </div>
                            </li>
                            <li onMouseOver={() => {this.tabSwitch1(5)}} className={tab.current1 ===5 ? 'cur':''}>
                                <div className='ul-bgBox'></div>
                                    <div className='Operation-font'>
                                        <h4>如何保障品牌机构的直播顺利进行呢？</h4>
                                        <h5>是否具备应急预案？直播监控工作谁来做？</h5>
                                    </div>
                            </li>
                            <li onMouseOver={() => {this.tabSwitch1(6)}} className={tab.current1 ===6 ? 'cur':''}>
                                    <div className='ul-bgBox'></div>
                                    <div className='Operation-font'>
                                        <h4>直播结束后，复盘工作怎么开展？</h4>
                                        <h5>数据汇总工作是否麻烦？销售数据是否可溯源？</h5>
                                    </div>
                            </li>
                            </ul>
                        </div>
                </div>
                <div className='Operation-OneStop'>
                        <div className='Operation-OneStops'>
                            <h2>诺云提供一站式服务</h2>
                            <h3>为您提供从策划案输出、主播、直播设备、场控、运营等各个环节的线上一体化服务+线下全线落地陪跑</h3>
                            <ul className='Operation-uls'>
                                <li>
                                    <img src='/assets/images/web/Operation/icon_1.png'></img>
                                    <h4>直播项目<br/>启动会</h4>
                                </li>
                                <li>
                                    <img src='/assets/images/web/Operation/icon_2.png'></img>
                                    <h4>专属策划<br/>方案制定</h4>
                                </li>
                                <li>
                                    <img src='/assets/images/web/Operation/icon_3.png'></img>
                                    <h4>活动营销<br/>物料设计</h4>
                                </li>
                                <li>
                                    <img src='/assets/images/web/Operation/icon_4.png'></img>
                                    <h4>销售渠道<br/>赋能会</h4>
                                </li>
                                <li>
                                    <img src='/assets/images/web/Operation/icon_5.png'></img>
                                    <h4>邀约触达</h4>
                                </li>
                                <li>
                                    <img src='/assets/images/web/Operation/icon_6.png'></img>
                                    <h4>社群裂变</h4>    
                                </li>
                                <li>
                                    <img src='/assets/images/web/Operation/icon_7.png'></img>
                                    <h4>直播现场<br/>执行</h4>
                                </li>
                                <li>
                                    <img src='/assets/images/web/Operation/icon_8.png'></img>
                                    <h4>直播复盘<br/>与总结</h4>
                                </li>
                            </ul>
                        </div>
                </div>
                <div className='Operation-aesthetic'>
                        <div className='aesthetic-top'>
                            <div className='aesthetic-tops'>
                                <h2>用美学的眼光，让直播成为艺术</h2>
                            </div>
                        </div>
                        <div className='aesthetic-bottom'>
                            <div className='aesthetic-bottoms'>
                                <ul className='aesthetic-ul'>
                                    <li>
                                        <img src='/assets/images/web/Operation/icon_9.png'></img>
                                        <div>
                                            <h4>统一视觉/VI设计，引爆信息传达效果</h4>
                                            <span>专注为医美机构视觉设计服务，提升品牌机构的直播间美学<br/>标准，实现最理想的传播效果。</span>
                                        </div>
                                    </li>
                                    <li>
                                        <img src='/assets/images/web/Operation/icon_10.png'></img>
                                        <div>
                                            <h4>专业的直播画面构图，突出活动营销主题</h4>
                                            <span>直播间“人、货、场”画面符合3:3:4黄金比例构图，助力品<br/>牌机构开启高品质直播盛宴。</span>
                                        </div>
                                    </li>
                                    <li>
                                        <img src='/assets/images/web/Operation/icon_11.png'></img>
                                        <div>
                                            <h4>直观地呈现商品信息，强化品销一体效果</h4>
                                            <span>强大的直播工具完美匹配品牌机构的内容营销，在清晰直观<br/>呈现商品的同时也能强化品牌形象，带来品销一体的效果。</span>
                                        </div>
                                    </li>
                                    <li>
                                        <img src='/assets/images/web/Operation/icon_12.png'></img>
                                        <div>
                                            <h4>黑科技引领美业行业变革，打造高清视频美颜</h4>
                                            <span>专为美业场景设计的人像美学科技，助力主播颜值稳定“不<br/>掉线”，实现综艺级视频传达。</span>
                                        </div>
                                    </li>
                                    <li>
                                        <img src='/assets/images/web/Operation/icon_13.png'></img>
                                        <div>
                                            <h4>专属品牌机构主题策划，加强活动的真实感</h4>
                                            <span>节日营销、周年嗨购、医生IP孵化等多种活动策划服务量身<br/>定做，突出品牌独特价值。</span>
                                        </div>
                                    </li>
                                    <li>
                                        <img src='/assets/images/web/Operation/icon_14.png'></img>
                                        <div>
                                            <h4>广电级直播执行标准，直播品质有保障</h4>
                                            <span>广电级应用设备配合立体式布光方案，兼具多机位画面切<br/>换，让直播间更具专业感和品牌性。</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                </div>
                <div className='Operation-Private'>
                        <div className='Operation-Privates'>
                            <h2>六大服务支撑团队，打造医美私域运营体系</h2>
                            <React.Fragment>
                                <div className="Operation-tab">
                                <ul className='tab-left'>
                                    <li onMouseOver={() => {this.tabSwitch(1)}} className={tab.current ===1 ? 'cur':''}>
                                        <h3>01</h3>
                                        <h6>专业指挥团队<br/><span>专业的医美私域运营操盘手，百万级…</span><div className='img-Box'></div></h6>
                                    </li>
                                    <li onMouseOver={() => {this.tabSwitch(2)}} className={tab.current ===2 ? 'cur':''}>
                                        <h3>02</h3>
                                        <h6>资深社群团队<br/><span>资深医美私域社群运营团队，提供专…</span><div className='img-Box'></div></h6>
                                    </li>
                                    <li onMouseOver={() => {this.tabSwitch(3)}} className={tab.current ===3 ? 'cur':''}>
                                        <h3>03</h3>
                                        <h6>优质主播团队<br/><span>全国各地优质主播资源，月均百万级…</span><div className='img-Box'></div></h6>
                                    </li>
                                    <li onMouseOver={() => {this.tabSwitch(4)}} className={tab.current ===4 ? 'cur':''}>
                                        <h3>04</h3>
                                        <h6>导演执行团队<br/><span>经验丰富的直播活动导演执行团队…&emsp;</span><div className='img-Box'></div></h6>
                                    </li>
                                    <li onMouseOver={() => {this.tabSwitch(5)}} className={tab.current ===5 ? 'cur':''}>
                                        <h3>05</h3>
                                        <h6>美学团队<br/><span>专业视觉设计团队全程陪跑，基于医…</span><div className='img-Box'></div></h6>
                                    </li>
                                    <li onMouseOver={() => {this.tabSwitch(6)}} className={tab.current ===6 ? 'cur':''}>
                                        <h3>06</h3>
                                        <h6>强大技术团队<br/><span>上千场直播操盘经验，严格执行42项…</span><div className='img-Box'></div></h6>
                                    </li>
                                </ul>
                                <div className="tab-right">
                                    <div className={tab.current ===1 ? 'tab_con_li current':'tab_con_li'}>
                                        <h3>专业指挥团队</h3><span></span>
                                        <p>专业的医美私域运营操盘手，百万级GMV场次的医美直播操盘经验，提供针对性医美直播活动流程和直播间品项设计，保障顺利执行落地。</p>
                                        <img src='/assets/images/web/Operation/img-1.png'></img>
                                    </div>
                                    <div className={tab.current ===2 ? 'tab_con_li current':'tab_con_li'}>
                                        <h3>资深社群团队</h3><span></span>
                                        <p>资深医美私域社群运营团队，提供专属医美社群运营SOP和社群成交SOP，交付医美机构社群运营、客户邀约、用户裂变、直播互动等各个环节玩法。</p>
                                        <img src='/assets/images/web/Operation/img-2.png'></img>
                                    </div>
                                    <div className={tab.current ===3 ? 'tab_con_li current':'tab_con_li'}>
                                        <h3>优质主播团队</h3><span></span>
                                        <p>全国各地优质主播资源，月均百万级的带货主播可实现精准对接，主持能力过硬，深度理解行业趋势和直播间用户，能够完美匹配医美机构对于主播的需求。</p>
                                        <img src='/assets/images/web/Operation/img-3.png'></img>
                                    </div>
                                    <div className={tab.current ===4 ? 'tab_con_li current':'tab_con_li'}>
                                        <h3>导演执行团队</h3><span></span>
                                        <p>经验丰富的直播活动导演执行团队，可根据品牌机构的特点指导拍摄计划，全面统筹服务直播场景布置，保障直播高质量执行和内容输出。</p>
                                        <img src='/assets/images/web/Operation/img-4.png'></img>
                                    </div>
                                    <div className={tab.current ===5 ? 'tab_con_li current':'tab_con_li'}>
                                        <h3>美学团队</h3><span></span>
                                        <p>专业视觉设计团队全程陪跑，基于医美机构品牌调性和活动主题，对启动会、直播现场所需物料进行统一设计，强化品牌展现，提升品牌认知。</p>
                                        <img src='/assets/images/web/Operation/img-5.png'></img>
                                    </div>
                                    <div className={tab.current ===6 ? 'tab_con_li current':'tab_con_li'}>
                                        <h3>强大技术团队</h3><span></span>
                                        <p>上千场直播操盘经验，严格执行42项直播项目标准流程，并带有突发应急保护方案；专属客户成功团队7x24小时陪伴式服务，秒回应、高效率，确保直播活动稳定落地。</p>
                                        <img src='/assets/images/web/Operation/img-6.png'></img>
                                    </div>
                                </div>
                                </div>
                            </React.Fragment>
                        </div>
                </div>
                <div className='Operation-case'>
                        <div className='Operation-cases'>
                            <h2>现场案例展示</h2>
                            <h5>用科技与品质，汇聚2000场直播执行的精髓，以美学标准创造独特的直播体验，助力品牌商家直播大卖</h5>
                            <div className='cases-hoverBox'>
                            <li onMouseOver={() => {this.tabSwitchs(1)}} className={tab.currents ===1 ? 'cur':''}>
                                <h6>项目启动会</h6>
                                <p>活动项目目标拆分、研讨品项及政策、销售渠道KPI设定、私域社群运营指导、直播间操作培训。</p>
                                <img src='/assets/images/web/Operation/img-7.png'></img>
                            </li>
                            <li onMouseOver={() => {this.tabSwitchs(2)}} className={tab.currents ===2 ? 'cur':''}>
                                <h6>直播场地搭建</h6>
                                <p>标准化流程搭建高质量直播间，设备套餐一键配齐，保证极致
的观看体验。</p>
                                <img src='/assets/images/web/Operation/img-8.png'></img>
                            </li>
                            <li onMouseOver={() => {this.tabSwitchs(3)}} className={tab.currents ===3 ? 'cur':''}>
                                <h6>专属直播系统</h6>
                                <p>活贴合美业场景的数字化科技直播产品，提升活动营销效率全方位保障直播方案顺利执行。</p>
                                <img src='/assets/images/web/Operation/img-9.png'></img>
                            </li>
                            <li onMouseOver={() => {this.tabSwitchs(4)}} className={tab.currents ===4 ? 'cur':''}>
                                <h6>智能数据追踪</h6>
                                <p>可视化数据大屏实时反馈数据，了解用户行为及内容偏好，支持销售数据可溯源。</p>
                                <img src='/assets/images/web/Operation/img-10.png'></img>
                            </li>
                            </div>
                        </div>
                </div>
                <div className='Operation-process'>
                        <div className='Operation-processs'>
                            <h2>创思万象服务流程</h2>
                            <div className='processs-Box'></div>
                            <ul>
                                <li>签约合同</li>
                                <li>确定排期</li>
                                <li>方案设计研讨</li>
                                <li>直播启动会</li>
                                <li>现场部署</li>
                                <li>直播彩排和执行</li>
                                <li>直播活动复盘</li>
                            </ul>
                        </div>
                </div>
                <div className='Operation-Cooperation'>
                        <div className='Operation-Cooperations'>
                            <h2>合作案例</h2>
                            <p>医美行业案例</p>
                            <div className='big_imgBox'></div>
                            <p>口腔行业案例</p>
                            <div className='small_imgBox'></div>
                            <span className='playback-btn'>获取直播案例回放</span>
                            <div className='masks-Box'></div>
                            <div className='popups-Box'>
                            <h3>立即咨询</h3>
                            <img src='assets/images/web/aboutus/contact.png'></img>
                            <h4>微信扫码<br/>获取最新全案策划方案</h4>
                        </div>
                        </div>
                </div>
                <div className='Operation-expect'>
                        <div className='Operation-expects'>
                            <h2>非常期待与您合作</h2>
                            <h4>超越期望，为您的每次合作创造更多价值</h4>
                            <span>
                                <a href='https://www14.53kf.com/webCompany.php?arg=10708171&kf_sign=TQyOTMTY1Oc4MDEyMDE5NjI5MzMyMDA2NzI3MDgxNzE%253D&style=1' target='blank'>联系我们</a>
                            </span>
                        </div>
                </div> */}
      </div>
    )
  }
}

export default operation