import React, { Component } from 'react'
import './footer.scss'

export class footer extends Component {
    render() {
        return (
            <div className='footer'>
                <div className='footers'>
                    <div className='guarantee'>
                        <ul>
                            <li>
                                <img src={require('../../assets/image/footer/icon.png')} alt="" />&nbsp;<span>7*24小时安全保障</span>
                            </li>
                            <li>
                                <img src={require('../../assets/image/footer/icon2.png')} alt="" />&nbsp;<span>全流程专业服务支持</span>
                            </li>
                            <li>
                                <img src={require('../../assets/image/footer/icon3.png')} alt="" />&nbsp;<span>多场景方案提供</span>
                            </li>
                            <li>
                                <img src={require('../../assets/image/footer/icon4.png')} alt="" />&nbsp;<span>国家高新技术企业</span>
                            </li>
                        </ul>

                    </div>
                    <div className='business'>
                        <div className='businessLeft'>
                            <img className='logo' src={require('../../assets/image/footer/logo.png')} alt="" /> 
                            <li><img src={require('../../assets/image/footer/add.png')}></img><span>郑州公司：郑州市高新技术开发区升龙又一城A座15楼</span></li>
                            <li><img src={require('../../assets/image/footer/mobile.png')} alt="" /><span>咨询热线：400-869-9377</span></li>
                            <li><img src={require('../../assets/image/footer/email.png')} alt="" /><span>渠道合作：yxd@inuoyun.com</span></li>
                            {/* <li>武汉公司&emsp;<span>武汉市东湖高新区光谷现代世贸中心C座508</span> </li>
                            <li>郑州公司&emsp;<span>郑州市高新技术开发区升龙又一城A座15楼</span></li>
                            <li>咨询热线&emsp;<span>4000-130-580</span></li>
                            <li>投诉建议&emsp;<span>027-65525618</span></li>
                            <li>渠道合作&emsp;<span>my@inuoyun.cn</span></li>
                            <div className='disBox'>
                                <img className='code' src={require('../../assets/image/footer/code1.png')} alt="" />
                                <img className='code' src={require('../../assets/image/footer/code2.png')} alt="" />
                            </div>
                            <div className='p'>
                            <p className='ye'>业务合作</p>
                            <p className='he'>合作品牌</p>
                            </div> */}
                        </div>
                        <div className='businessRight'>
                            <ul>
                            <li>
                                <h6>产品服务</h6>
                                <a href="">诺云直播</a>
                                <a href="">全案策划</a>
                                <a href="">机构代运营</a>
                                <a href="">诺云SCRM</a>
                                <a href="">专属订制</a>
                            </li>
                            <li>
                                <h6>服务与支持</h6>
                                <a href="">帮助中心</a>
                                <a href="">美业商学院</a>
                                <a href="">生态合作</a>
                                <a href="">渠道加入</a>
                            </li>
                            <li>
                                <h6>解决方案</h6>
                                <a href="">医美方案</a>
                                <a href="">口腔方案</a>
                                <a href="">植发方案</a>
                                <a href="">眼科方案</a>
                            </li>
                            <li>
                                <h6>价值共生</h6>
                                <a href="">美业白皮书</a>
                                <a href="">荣誉见证</a>
                                <a href="">启明星计划</a>
                                <a href="">美创汇</a>
                            </li>
                            <li>
                            <h6>关于我们</h6>
                                <a href="">创思万象</a>
                                <a href="">媒体报道</a>
                                <a href="">社会招聘</a>
                                <a href="">校园招聘</a>
                            </li>
                            <li>
                                <div className='disBox'>
                                    <img className='code' src={require('../../assets/image/footer/code2.png')} alt="" /><p style={{width:100,transform: `translateX(-12px)`}}>官方微信公众号</p>
                                    <img className='code' src={require('../../assets/image/footer/code1.png')} alt="" /><p style={{width:100,transform: `translateX(-12px)`}}>创思万象视频号</p>
                                </div>
                            </li>
                            
                            </ul>
                        </div>
                        
                    </div>
                    <div className='list'>
                            <li>
                                <a className='link-wen' href='http://www.csmixc.com/assets/images/business_certificate.pdf' target='_blank'>
                                    网络文化许可证-豫网文（2022）1719-052号
                                </a>
                                </li>
                            <li>
                                <a>用户协议</a>
                                <a>Cookie</a>
                                <a>隐私政策</a>
                                <a>廉洁举报</a>
                                <a>安全合规</a>
                            </li>
                    </div>
                </div>
            </div>
        )
    }
}

export default footer