import React, { Component } from 'react'
import Header from '../../components/Header/header'
import Footer from '../../components/Footer/footer'
import './about.css'

export class about extends Component {
  render() {
    return (
      <div className='pc-about'>
        <Header />
        <div className='banner'>
          <div className='banners'>
            <h2>创思万象</h2>
            <h5>和您一起做美的生意</h5>
            <p>创思万象专注于消费医疗机构，懂运营，更懂美业！我们致力于为美业机构提供数字化、大数<br/>据、全案策划执行、品牌营销等服务，提升机构整体运营效率，降本增效，实现业绩增长，让<br/>美业机构成功。我们的核心服务板块包括：美业数字化技术服务、全案策划执行、品牌全案咨<br/>询、软件技术服务等。我们靠专业和口碑赢得的5000+美业机构的信任。
            </p>
            <p>
              创思万象存在的意义是服务美业，帮助美业机构成功，我们所有的选择都围绕着机构成功出<br/>发。我们拥有美业专业领域的全案策划执行团队，我们更懂新人群、新场景、新美业！我们不<br/>断学习、总结、改进，专注美业，持续不断的为美业机构赋能，为美业生态赋能，为美业机构<br/>贡献长期价值，实现价值共生，成为国内美业机构运营服务领域里最被信任的引领者。
            </p>
          </div>
        </div>
        <div className='culture'>
          <div className='cultures'>
            <h2>企业文化</h2>
            <p>CORPORATE CULTURE</p>
            <div className='valueBox'>
            <li>
                <img src={require('../../assets/image/about/icon1.png')}></img>
                <div>
                  <h3>企业文化</h3>
                  <h6>CORPORATE CULTURE</h6>
                  <span>以终为始：所有人、所有流程、所有事为目标负责<br/>自驱力学习：崇尚自驱式持续学习能力是我们唯一<br/>可以依赖的能力</span>
                </div>
              </li>
              <li>
                <img src={require('../../assets/image/about/icon2.png')}></img>
                <div>
                  <h3>价值观</h3>
                  <h6>VALUES</h6>
                  <span>专注、信任、安全、开放、创新、共赢</span>
                </div>
              </li>
              <li>
                <img src={require('../../assets/image/about/icon3.png')}></img>
                <div>
                  <h3>企业愿景</h3>
                  <h6>CORPORATE VISION</h6>
                  <span>致力于成为中国美业机构运营服务领域里最被信任<br/>的引领者，成为最值得信赖的美业机构服务品牌。</span>
                </div>
              </li>
              <li>
                <img src={require('../../assets/image/about/icon4.png')}></img>
                <div>
                  <h3>使命</h3>
                  <h6>MISSION</h6>
                  <span>为美业贡献长期价值，帮助机构成功</span>
                </div>
              </li>
              
            </div>
          </div>
        </div>
        <div className='criterion'>
          <div className='criterions'>
            <h2>指导行动六大准则</h2>
            <div className='criterionBox'>
              <li>
                <img src={require('../../assets/image/about/icon5.png')}></img>
                <p>深刻理解美业机构需求<br/>机构成功我们才成功</p>
              </li>
              <li>
                <img src={require('../../assets/image/about/icon6.png')}></img>
                <p>丰富的执行经验是服务的基础</p>
              </li>
              <li>
                <img src={require('../../assets/image/about/icon7.png')}></img>
                <p>大数据赋能创造最佳实践路径</p>
              </li>
              <li>
                <img src={require('../../assets/image/about/icon8.png')}></img>
                <p>客户为导向服务性绩效文化</p>
              </li>
              <li>
                <img src={require('../../assets/image/about/icon9.png')}></img>
                <p>整合最高效运营方法及工具</p>
              </li>
              <li>
                <img src={require('../../assets/image/about/icon10.png')}></img>
                <p>24项体系严格保障服务</p>
              </li>
            </div>
          </div>
        </div>
        <div className='declaration'>
          <div className='declarations'>
            <div className='LeftBox'>
              <h3>服务两大标准</h3>
              <p>是否为客户创造价值<br/>是否被客户所认可</p>
              <img src={require('../../assets/image/about/img1.png')}></img>
            </div>
            <div className='RightBox'>
              <h3>创思万象<span>宣言</span></h3>
              <p>我们全力以赴 因为我们相信服务好客户，实际上也成就了自己<br/>
              我们追求卓越 因为我们渴望成长，我们相信不断学习，会让我们更优秀更美好<br/>
              我们热爱团队 因为我们深知，每个人都有优点，也有缺点，唯有包容、协作、补位、支持，才能紧密合作，平凡<br/>人做非凡事；<br/>
              我们严格要求 因为对市场的了解，让我们深刻了解市场竞争的残酷，只有注重长期价值和口碑才能生存，我们的<br/>高标准，不是来自于客户，而是来自于我们自己；
              我们享受工作 Hard Work，Hard Play，我们把事情做好，享受工作带来的快乐，并且充满热情地拥抱生活；</p>
              <img src={require('../../assets/image/about/img2.png')}></img>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default about