function openInWebview () {
    var ua = navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
        return false
    } else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
        return false
    } else if (ua.match(/WeiBo/i) == "weibo") {
        return false
    } else {
        if (ua.match(/Android/i) != null) {
            return ua.match(/browser/i) == null
        } else if (ua.match(/iPhone/i) != null) {
            return ua.match(/safari/i) == null
        } else {
            return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null)
        }
    }
  }
  
  // 使用方式
  if (openInWebview()) {
    // 在app内打开
    // to do something
  } else {
    // 其他地方
    // 发起微信授权
  }
  
  function isMobile(){
   if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
          || navigator.userAgent.match(/iPad/i)
          || navigator.userAgent.match(/iOS/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/Windows Phone/i)
          || navigator.userAgent.toLowerCase().indexOf("MEX-WebDownLoad/1.1.3 (x86_64Simulator; U; iOS 10.3.1; zh-CN)") != -1
   ) {
        // location.replace('/m');
        return true
   };
   return false;
  }
  
  export default isMobile