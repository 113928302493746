import React from 'react'
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import MobileHeader from '../../components/MoblieHeader/mobileHeader'
import MobileFooter from "../../components/MobileFooter/MobileFooter";
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay, Pagination } from 'swiper'
import "swiper/css";
import isMobile from "../../utils/isInWebView";
import "./channel.css";
const mobile = isMobile();

function channel() {
  return (
    <div className='pc-channel'>
        <Header />
        <div className='banner'>
            <div className='banners'>
                <h2>创思万象全国渠道伙伴火热招商中</h2>
                <p>满足美业市场营销需求，携手诺云共拓千亿市场！</p>
                <p className='btnBox'>
                    <a>我要代理</a>
                    <a>了解更多</a>
                </p>
            </div>
        </div>
        <div className='mechanism'>
          <div className='mechanisms'>
            <div className='customer'>
              <li>
                <div>
                  <h4>5000+</h4>
                  <p>美业机构客户</p>
                </div>
                <img src={require('../../assets/image/channel/icon1.png')}></img>
              </li>
              <li><div>
                  <h4>80%+</h4>
                  <p>机构续费比例</p>
                </div>
                <img src={require('../../assets/image/channel/icon2.png')}></img></li>
              <li><div>
                  <h4>20000+<span>场</span></h4>
                  <p>私域直播</p>
                </div>
                <img src={require('../../assets/image/channel/icon3.png')}></img></li>
              <li><div>
                  <h4>80亿+<span>GMV</span></h4>
                  <p>成交额</p>
                </div>
                <img src={require('../../assets/image/channel/icon4.png')}></img></li>
            </div>
            <div className='Hand'>
              <h2>携手诺云 共掘千亿市场</h2>
              <div>
                <li>
                  <img src={require('../../assets/image/channel/icon5.png')}></img>
                  <span>Saas直播服务</span>
                </li>
                <li>
                <img src={require('../../assets/image/channel/icon6.png')}></img>
                  <span>全案策划执行服务</span>
                </li>
                <li>
                <img src={require('../../assets/image/channel/icon7.png')}></img>
                  <span>线上社群运营</span>
                </li>
                <li>
                <img src={require('../../assets/image/channel/icon8.png')}></img>
                  <span>美业品牌咨询</span>
                </li>
              </div>
            </div>
          </div>
        </div>
        <div className='cooperation'>
          <div className='cooperations'>
            <h2>为什么要成为创思万象渠道合作伙伴</h2>
            <p className='ps'>诺云凭借成熟的商业模式、完整的培训赋能体系及无后顾之忧的售前、售后服务，真正意义上成为渠道伙伴们的强大联盟和有力支撑。</p>
            <div className='brand'>
              <li>
                <img src={require('../../assets/image/channel/icon9.png')}></img>
                <h4>头部品牌背书</h4>
                <p className='p'>
                  <img src={require('../../assets/image/channel/img1.png')}></img><span>腾讯健康等强生态合作伙伴</span>
                </p>
                <p className='p'>
                <img src={require('../../assets/image/channel/img2.png')}></img><span>国家高新技术企业</span>
                </p>
                <p className='p'>
                <img src={require('../../assets/image/channel/img3.png')}></img><span>医美头部机构占有率90%以上</span>
                </p>
              </li>
              <li>
                <img src={require('../../assets/image/channel/icon10.png')}></img>
                <h4>产品及服务先进</h4>
                <p className='p'>
                  <img src={require('../../assets/image/channel/img4.png')}></img><span>腾讯健康等强生态合作伙伴</span>
                </p>
                <p className='p'>
                <img src={require('../../assets/image/channel/img5.png')}></img><span>国家高新技术企业</span>
                </p>
                <p className='p'>
                <img src={require('../../assets/image/channel/img6.png')}></img><span>医美头部机构占有率90%以上</span>
                </p>
              </li>
              <li>
                <img src={require('../../assets/image/channel/icon11.png')}></img>
                <h4>全案策划最佳实践</h4>
                <p className='p'>
                  <img src={require('../../assets/image/channel/img7.png')}></img><span>腾讯健康等强生态合作伙伴</span>
                </p>
                <p className='p'>
                <img src={require('../../assets/image/channel/img8.png')}></img><span>国家高新技术企业</span>
                </p>
                <p className='p'>
                <img src={require('../../assets/image/channel/img9.png')}></img><span>医美头部机构占有率90%以上</span>
                </p>
              </li>
              <li>
                <img src={require('../../assets/image/channel/icon12.png')}></img>
                <h4>大数据行业赋能</h4>
                <p className='p'>
                  <img src={require('../../assets/image/channel/img10.png')}></img><span>腾讯健康等强生态合作伙伴</span>
                </p>
                <p className='p'>
                <img src={require('../../assets/image/channel/img11.png')}></img><span>国家高新技术企业</span>
                </p>
                <p className='p'>
                <img src={require('../../assets/image/channel/img11.png')}></img><span>医美头部机构占有率90%以上</span>
                </p>
              </li>
            </div>
          </div>
        </div>
        <div className='advantage'>
          <div className='advantages'>
            <h2>合作优势</h2>
            <p className='advantage-p'>携手诺云共拓千亿市场</p>
            <div className='rich'>
              <li>
                <img className='imgs' src={require('../../assets/image/channel/1L-img.png')}></img>
                <span>丰厚返利</span>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>高回报返利体系</span>
                </p>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>高续费比例带来稳定营收</span>
                </p>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>季/年度优秀代理商激励</span>
                </p>
              </li>
              <li>
              <img className='imgs' src={require('../../assets/image/channel/1L-img1.png')}></img>
                <span>体系化培训</span>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>定期线上产品培训</span>
                </p>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>定期线下新兵训练营</span>
                </p>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>季/渠道经理定期走访辅导</span>
                </p>
              </li>
              <li>
                <img className='imgs' src={require('../../assets/image/channel/1L-img2.png')}></img>
                <span>市场支持</span>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>产品画册、四折页等物料</span>
                </p>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>专属授权服务商挂牌</span>
                </p>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>拓客话术等销售资料</span>
                </p>
              </li>
            </div>
            <div className='richs'>
              <li>
                <img className='imgs' src={require('../../assets/image/channel/1L-img3.png')}></img>
                <span>市场支持</span>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>7*12小时在线人工客服</span>
                </p>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>专属代理商服务沟通群</span>
                </p>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>专属客户沟通服务群</span>
                </p>
              </li>
              <li>
                <img className='imgs' src={require('../../assets/image/channel/1L-img4.png')}></img>
                <span>市场支持</span>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>线下交流和沙龙</span>
                </p>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>运营能力培训服务</span>
                </p>
                <p className='rich-p'>
                  <img className='dui' src={require('../../assets/image/channel/dui.png')}></img>
                  <span>市场销售集训服务</span>
                </p>
              </li>
            </div>
          </div>
        </div>
        <div className='strategy'>
          <div className='strategys'>
            <h2>创思万象渠道合作伙伴策略</h2>
            <p className='strategy-p'>创思万象依据渠道伙伴的自身定位以及发展规划，设立4种不同合作模式，并设立相应的培训认证体系与合作服务政策。</p>
            <div className='certification'>
              <li>
                <img src={require('../../assets/image/channel/2L-img1.png')}></img>
                <p className=''>认证合作伙伴</p>
              </li>
              <li>
                <img src={require('../../assets/image/channel/2L-img2.png')}></img>
                <p>金牌合作伙伴</p>
              </li>
              <li>
                <img src={require('../../assets/image/channel/2L-img3.png')}></img>
                <p>金牌技术合作伙伴</p>
              </li>
              <li>
                <img src={require('../../assets/image/channel/2L-img4.png')}></img>
                <p>战略合作伙伴</p>
              </li>
            </div>
          </div>
        </div>
        <div className='process'>
          <div className='processs'>
            <h2>合作伙伴申请流程</h2>
            <div className='iconBox'>
              <li>
                <img src={require('../../assets/image/channel/3L-icon1.png')}></img>
              </li>
              <li>
                <img src={require('../../assets/image/channel/3L-icon2.png')}></img>
              </li>
              <li>
                <img src={require('../../assets/image/channel/3L-icon3.png')}></img>
              </li>
              <li>
                <img src={require('../../assets/image/channel/3L-icon4.png')}></img>
              </li>
            </div>
            <img className='lang' src={require('../../assets/image/channel/lang.png')}></img>
            <div className='applyBox'>
              <p>提交申请</p>
              <p>审核资料</p>
              <p>洽谈合作</p>
              <p>签约合作</p>
            </div>
            <a href=''>立即申请<img src={require('../../assets/image/channel/yjt.png')}></img></a>
          </div>
        </div>
        <div className='share'>
          <h2>创思万象愿与渠道合作伙伴互联共享，共建医美产业新生态</h2>
          <a href=''>立即加入<img src={require('../../assets/image/channel/yjt.png')}></img></a>
        </div>
        <Footer/>
    </div>
  )
}

export default channel